<template>
  <div class="new-clients-container">
    <admin-header-vue alias="" pageName="Update Topic" />
    <form @submit.prevent="handleSubmit">
      <div class="new-client">
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="true"
              :value="newTopic.topicName"
              :min="1"
              type="text"
              @onEnter="handleSubmit"
              :max="150"
              :submitted="submitted"
              @onChangeName="onChangeName"
              label="Topic Name" />
          </div>
          <div class="column is-4">
            <input-vue
              :required="false"
              :value="newTopic.helpText"
              type="text"
              :max="200"
              @onEnter="handleSubmit"
              :submitted="submitted"
              @onChangeName="onChangehelp"
              label="Help Text (Description)" />
          </div>
          <div class="column is-4">
            <p>Is Standard?</p>
            <b-checkbox v-model="newTopic.isStandard"> </b-checkbox>
          </div>
        </div>
        <div v-if="!newTopic.isStandard && module === 'voc'" class="columns">
          <div class="column is-4">
            <select-vue
              :value="newTopic.categoryId"
              label="Category Name"
              placeholder="Select a category"
              :list="categoryList"
              :submitted="submitted"
              :required="true"
              @onChangeSelection="onChangeCategoryName"
              id="category_id"
              name="category_name" />
          </div>
          <div class="column is-4">
            <select-vue
              :value="newTopic.parentId"
              label="Parent Topic Name"
              placeholder="Select a parent topic name"
              :list="parentTopicList"
              :submitted="submitted"
              :required="false"
              @onChangeSelection="onChangeParentTopicName"
              id="topic_id"
              name="topic_name" />
          </div>
        </div>

        <div v-if="!newTopic.isStandard && module === 'voe'" class="columns form-group">
          <div class="column is-4">
            <!-- industry treeselect -->
            <p>Industry<sup class="display-flex required">*</sup></p>
            <treeselect
              :multiple="false"
              :options="allIndustryList"
              :sort-value-by="sortValueBy"
              :default-expand-level="1"
              placeholder="Select Industry"
              v-model="newTopic.industryId" />
              <div v-if="submitted && isIndustryIdInValid" class="required-error">
                Industry is required
              </div>
            </div>
          <div class="column is-4">
            <select-vue
              :value="newTopic.parentId"
              label="Parent Topic Name"
              placeholder="Select a parent topic name"
              :list="parentTopicList"
              :submitted="submitted"
              :required="false"
              @onChangeSelection="onChangeParentTopicName"
              id="topic_id"
              name="topic_name" />
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <input-vue
              :required="false"
              type="textarea"
              :submitted="submitted"
              @input="handleInclusionInput"
              :value="formInclusions"
              label="Inclusions (Each line represents a keyword)" />
            <div class="required-error"
            v-if="submitted && isInclusionsInvalid">
              {{inclusionsError}}
              <!-- Max 5000 keywords accepted. -->
            </div>
          </div>
          <div class="column is-4">
            <input-vue
              :required="false"
              type="textarea"
              :submitted="submitted"
              :value="formExclusions"
              @input="handleExclusionInput"
              label="Exclusions (Each line represents a keyword)" />
            <div class="required-error"
            v-if="submitted && isExclusionsInvalid">
              {{exclusionsError}}
              <!-- Max 5000 keywords accepted. -->
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <div class="submit-button">
              <b-button @click.prevent="handleSubmit">Update</b-button>
              <b-button @click.prevent="handleCancel">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AdminHeaderVue from '../../components/AdminHeader.vue'
import InputVue from '../../components/Input.vue'
import SelectVue from '../../components/Select.vue'
import Snackbar from '../../../../components/Snackbar'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

/**
 * Form to update a Topic.
 * @displayName UpdateTopic
 */
export default {
  name: 'UpdateTopic',
  components: {
    AdminHeaderVue,
    InputVue,
    SelectVue,
    Treeselect
  },
  data () {
    return {
      /**
       * Object containing form data which will be sent to the API
       * @type {Object}
       */
      newTopic: {
        topicName: null,
        categoryId: null,
        industryId: null,
        isStandard: false,
        parentId: null,
        topicId: null,
        helpText: null,
        keywords: {
          inclusions: [],
          exclusions: []
        },
        areInclusionsUpdated: false,
        module: null
      },
      /**
       * Field to track the form submission status, to be used to show/hide the validation messages
       * @type {Boolean}
       */
      submitted: false,

      /**
       * Field to track if the topic name input is valid
       * @type {Boolean}
       */
      isTopicNameInvalid: true,

      /**
       * Field to track if the category input is valid
       * @type {Boolean}
       */
      isCategoryIdInvalid: true,

      /**
       * Field to track if the parent topic input is valid
       * @type {Boolean}
       */
      isParentIdInvalid: false,

      /**
       * Field to track if the tagging keyword inclusions input is valid
       * @type {Boolean}
       */
      isInclusionsInvalid: false,

      /**
       * Field to track if the tagging keyword exclusions input is valid
       * @type {Boolean}
       */
      isExclusionsInvalid: false,

      /**
       * model for binding the Inclusions keywords input field in the form
       * @model
       * @type {String}
       */
      formInclusions: '',

      /**
       * model for binding the Exclusions keywords input field in the form
       * @model
       * @type {String}
       */
      formExclusions: '',
      inclusionsError: '',
      exclusionsError: '',
      sortValueBy: 'ORDER_SELECTED'
    }
  },
  computed: {
    ...mapState('categories', ['categoryList']),
    ...mapState('topics', ['parentTopicList', 'topicDetails']),
    ...mapState('industries', ['allIndustryList', 'allIndustryIds']),
    module () {
      return this.$route.query.module
    },
    isIndustryIdInValid () {
      if (this.newTopic.isStandard && this.newTopic.industryId) {
        return false
      } else {
        if (!this.newTopic.industryId) {
          return true
        } else {
          return false
        }
      }
    }
  },
  watch: {
    'newTopic.industryId' () {
      if (this.newTopic.industryId) {
        this.getFilteredVoeTopicList(this.newTopic.industryId)
      }
    },
    parentTopicList () {
      const parentInTopicList = this.parentTopicList.find(t => t.topic_id === this.newTopic.parentId)
      if (!parentInTopicList) {
        this.newTopic.parentId = null
      }
    },
    topicDetails () {
      const {
        topicName,
        isStandard,
        parentId,
        categoryId,
        helpText,
        keywords,
        industryId
      } = this.topicDetails
      this.newTopic.topicName = topicName
      this.newTopic.isStandard = isStandard
      this.newTopic.parentId = parentId
      this.newTopic.categoryId = categoryId
      this.newTopic.industryId = industryId
      this.newTopic.helpText = helpText
      this.formInclusions = keywords ? this.parseArrayToCsv(keywords.inclusions) : '' // keywords ? keywords.inclusions.join('\n') : ''
      this.formExclusions = keywords ? this.parseArrayToCsv(keywords.exclusions) : '' // keywords ? keywords.exclusions.join('\n') : ''
      this.newTopic.keywords = keywords || { inclusions: [], exclusions: [] }
    },
    'newTopic.isStandard' () {
      if (this.newTopic.isStandard) {
        this.newTopic.categoryId = null
        this.newTopic.parentId = null
        this.resetFilteredParentTopics()
        this.newTopic.industryId = null
      }
    }
  },
  beforeMount () {
    this.resetCategoryList()
    this.resetFilteredParentTopics()
  },
  mounted () {
    if (!this.module) { this.$router.push('/admin/topic') }
    this.newTopic.module = this.module
    this.getSingleTopic({ topicId: this.$route.params.id, module: this.module })
    this.getCategoryList()
    this.getHeirarchicalIndustryList()
    this.newTopic.topicId = Number(this.$route.params.id)
  },
  methods: {
    ...mapActions('topics', [
      'getFilteredTopicList',
      'updateTopic',
      'getSingleTopic',
      'resetFilteredParentTopics',
      'getFilteredVoeTopicList'
    ]),
    ...mapActions('categories', ['getCategoryList', 'resetCategoryList']),
    ...mapActions('industries', ['getHeirarchicalIndustryList']),

    parseArrayToCsv (input) {
      const fInput = []
      if (input) {
        input.forEach(e => {
          e.keywords.forEach(k => {
            const line = e.attribute + ', ' + k
            fInput.push(line)
          })
        })
        return fInput.join('\n')
      } else {
        return ''
      }
    },

    /**
     * Handles the keyword input in inclusions field and prepares the array from the string input using the delimiter as "\n"(newline).
     * @param {String} data
     * @public
     */
    handleInclusionInput (data) {
      if (data) {
        const keywords = data.split('\n')
        if (keywords.length > 5000) {
          this.isInclusionsInvalid = true
          this.inclusionsError = 'Max 5000 keywords accepted.'
        } else {
          const objArray = []
          for (const element of keywords) {
            const temp = element.trim().split(',')
            if (temp.length !== 2) {
              this.isInclusionsInvalid = true
              this.inclusionsError = 'Invalid input'
              return
            }
            const obj = {
              attribute: temp[0], keyword: [temp[1]]
            }
            objArray.push(obj)
          }
          const attrSet = new Set(objArray.map(e => e.attribute))
          const finalArray = []
          attrSet.forEach(element => {
            const temp = []
            const o = {
              attribute: element,
              keywords: temp.concat(...objArray.filter(e => e.attribute === element).map(e => {
                return e.keyword[0].trim()
              }))
            }
            finalArray.push(o)
          })
          this.newTopic.keywords.inclusions = finalArray
          this.isInclusionsInvalid = false
          this.inclusionsError = ''
        }
      } else {
        this.newTopic.keywords.inclusions = []
        this.isInclusionsInvalid = false
        this.inclusionsError = ''
      }
    },

    /**
     * Handles the keyword input in exclusions field and prepares the array from the string input using the delimiter as "\n"(newline).
     * @param {String} data
     * @public
     */
    handleExclusionInput (data) {
      if (data) {
        const keywords = data.split('\n')
        if (keywords.length > 5000) {
          this.isExclusionsInvalid = true
          this.exclusionsError = 'Max 5000 keywords accepted.'
        } else {
          const objArray = []
          for (const element of keywords) {
            const temp = element.trim().split(',')
            if (temp.length !== 2) {
              this.isExclusionsInvalid = true
              this.exclusionsError = 'Invalid input'
              return
            }
            const obj = {
              attribute: temp[0], keyword: [temp[1]]
            }
            objArray.push(obj)
          }
          const attrSet = new Set(objArray.map(e => e.attribute))
          const finalArray = []
          attrSet.forEach(element => {
            const temp = []
            const o = {
              attribute: element,
              keywords: temp.concat(...objArray.filter(e => e.attribute === element).map(e => {
                return e.keyword[0].trim()
              }))
            }
            finalArray.push(o)
          })
          this.newTopic.keywords.exclusions = finalArray
          this.isExclusionsInvalid = false
          this.exclusionsError = ''
        }
      } else {
        this.newTopic.keywords.exclusions = []
        this.isExclusionsInvalid = false
        this.exclusionsError = ''
      }
    },

    /**
     * Gets called on input in "Topic Name" field. It sets the input value as topicName in the newTopic object used for request data.
     * @param {String} data
     */
    onChangeName (name, isInvalid) {
      this.newTopic.topicName = name
      this.isTopicNameInvalid = isInvalid
    },

    /**
     * Gets called on input in "Help Text" field. It sets the input value as helpText in the newTopic object used for request data.
     * @param {String} value help text description value
     */
    onChangehelp (value, isValid) {
      this.newTopic.helpText = value
      if (value === '') {
        this.newTopic.helpText = null
      }
    },

    /**
     * Gets called on input in "Parent Topic Name" field. It sets the input value as parentId in the newTopic object used for request data.
     * @param {Number} id Topic id to be set as parent of the new topic
     * @param {Boolean} isInvalid validity of input
     */
    onChangeParentTopicName (id, isInvalid) {
      this.newTopic.parentId = id
      this.isParentIdInvalid = isInvalid
    },

    /**
     * Gets called on category selection/change in "Category Name" field. It sets the input value as categoryId in the newTopic object used for request data.
     * and also fetch the list of topics associated to the selected category to populate the "Parent Topic Name" field
     * @param {Number} id Category id
     * @param {Boolean} isValid validity of input
     */
    onChangeCategoryName (id, isInvalid) {
      this.newTopic.categoryId = id
      this.isCategoryIdInvalid = isInvalid
      if (this.newTopic.categoryId != null) {
        this.getFilteredTopicList(this.newTopic.categoryId)
      }
    },

    /**
     * Gets called from handelSubmit function to check if there are any updates to put.
     */
    checkChanges () {
      // this.checkKeywordsWarnings()
      // const topicDetailsInclusionsCSV = this.parseArrayToCsv(this.topicDetails.keywords ? this.topicDetails.keywords.inclusions : null)
      // const topicDetailsExclusionsCSV = this.parseArrayToCsv(this.topicDetails.keywords ? this.topicDetails.keywords.exclusions : null)
      const newInclusionsCSV = this.parseArrayToCsv(this.newTopic.keywords ? this.newTopic.keywords.inclusions : null)
      const newExclusionsCSV = this.parseArrayToCsv(this.newTopic.keywords ? this.newTopic.keywords.exclusions : null)
      if (this.formInclusions !== newInclusionsCSV) {
        this.newTopic.areInclusionsUpdated = true
        return false
      } else {
        this.newTopic.areInclusionsUpdated = false
      }
      if (this.formExclusions !== newExclusionsCSV) { return false }
      for (const field in this.topicDetails) {
        if (field !== 'keywords') {
          if (this.topicDetails[field] !== this.newTopic[field]) {
            return false
          }
        }
      }
      return true
    },

    /**
     * Handles the form submission by validating the input and calls the addTopic API.
     * @public
     */
    handleSubmit (e) {
      this.submitted = true
      if (!this.checkChanges() && this.newTopic.module) {
        if (this.newTopic.isStandard) {
          if (!(this.isTopicNameInvalid || this.isInclusionsInvalid || this.isExclusionsInvalid)) {
            this.updateTopic(this.newTopic)
          }
        } else {
          if (this.module === 'voc') {
            if (!(this.isTopicNameInvalid || this.isInclusionsInvalid || this.isExclusionsInvalid ||
              this.isParentIdInvalid ||
              this.isCategoryIdInvalid)) {
              this.updateTopic(this.newTopic)
            }
          } else if (this.module === 'voe') {
            if (!(this.isTopicNameInvalid || this.isInclusionsInvalid || this.isExclusionsInvalid ||
              this.isParentIdInvalid ||
              this.isIndustryIdInValid)) {
              this.updateTopic(this.newTopic)
            }
          }
        }
      } else {
        let message = (this.isInclusionsInvalid === true || this.isExclusionsInvalid === true) ? 'Invalid input' : 'No Updates Found'
        if (!this.newTopic.module) {
          message = 'No module info found!'
        }
        Snackbar({ message: message, type: 'is-danger' })
      }
    },
    handleCancel () {
      this.$router.push('/admin/topic')
    }

    // /**
    //  * Gets called from handelSubmit function to check for any duplicate keywords in Inclusions and Exclusions fields.
    //  */
    // checkKeywordsWarnings () {
    //   const intersection = this.newTopic.keywords.inclusions.filter(element => this.newTopic.keywords.exclusions.includes(element))
    //   if (intersection.length) {
    //     Snackbar({ message: 'Inclusions and Exclusions list contains some common keywords', type: 'is-warning' })
    //   }
    //   const inclusionsSet = new Set(this.newTopic.keywords.inclusions)
    //   const exclusionsSet = new Set(this.newTopic.keywords.exclusions)
    //   if (inclusionsSet.size && inclusionsSet.size !== this.newTopic.keywords.inclusions.length) {
    //     Snackbar({ message: 'Inclusions keywords list contains some duplicates', type: 'is-warning' })
    //   }
    //   if (exclusionsSet.size && exclusionsSet.size !== this.newTopic.keywords.exclusions.length) {
    //     Snackbar({ message: 'Exclusions keywords list contains some duplicates', type: 'is-warning' })
    //   }
    // }
  }
}
</script>

<style lang="scss" scoped>
.new-clients-container {
   margin-top: 20px;
    background: var(--primary);
    border-radius: 4px;
  .new-client {
    .columns {
      margin: 0px;
      .required-error {
          position: absolute;
          color: red;
          position: absolute;
          font-size: 12px;
        }
      .column {
        .upload {
          width: 200px;
          height: 60px;
          align-items: center;
          justify-content: center;
          border: 1px solid #dbdbdb;
          border-radius: 4px;
          img {
            width: 200px;
            height: 58px;
            object-fit: contain;
            padding: 5px;
          }
        }
        .submit-button {
          display: flex;
          justify-content: flex-start;
          padding-top: 20px;
          .button {
            height: 100%;
            padding: 5px 10px;
            background: #2780eb;
            color: white;
            border: none;
            margin-right: 10px;
          }
        }
      }
      .column-flex {
        display: flex;
        align-items: center;
      }
    }
  }
}

::v-deep .vue-treeselect {
          .vue-treeselect__placeholder {
              font-family: roboto;
              font-size: 14px;
          }
          .vue-treeselect__control {
            background: transparent;
            border: none;
            border: var(--tertiary-border-color) !important;
            border-radius: 4px !important;
            color: var(--user-dropdown-text-color) !important;
              input {
                color: var(--user-dropdown-text-color) !important;
              }
              .vue-treeselect__value-container {
                .vue-treeselect__single-value {
                  color: var(--user-dropdown-text-color) !important;
                  font-family: roboto;
                  font-size: 14px;
                }
              }
          }
            .vue-treeselect__menu {
              background: var(--dropdown-backgroud) !important;
              color: var(--secondary-text-color) !important;
              border: none;
              border: 1px solid lightgray;

              .vue-treeselect__option--highlight{
                background-color: var(--dropdown-background-hover) !important;
              }
            }
        }
        ::v-deep.vue-treeselect--single .vue-treeselect__option--selected {
          background: var(--dropdown-background-hover)
        }

</style>

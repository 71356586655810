<template>
  <div class="form-group">
    <p v-if="!showLabel">{{ label }}<sup v-if="required" class="display-flex required">*</sup></p>
    <b-select
      v-model="selected"
      :disabled="disabled"
      :placeholder="placeholder"
      class="form-control"
      :class="{ 'is-invalid': $v.selected.$error && submitted && required }">
      <option :value="null">
        {{placeholder}}
      </option>
      <option v-for="(option,index) in list" :value="id?option[id]:option" :key="index">
        {{ option[name] }}
      </option>
    </b-select>
    <div
      v-if="!$v.selected.required && submitted && required"
      class="required-error">
      {{ label }} is required
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

/** This component contains the select-dropdowns found on the admin panel */
export default {
  name: 'Select',
  props: {
    /** This prop contains the text for the label of the dropdown */
    label: String,
    /** This prop contains the data used to populate the dropdown */
    list: Array,
    /** This prop contains the selected option of dropdown */
    value: [Number, Object, Boolean, String],
    /** This prop contains the placeholder text for the dropdown input */
    placeholder: String,
    /** This prop contains the id of the dropdown input */
    id: String,
    /** This prop contains the name of the input as it would appear in the submitted form */
    name: String,
    /** This prop indicates whether or not a form submission has been attempted */
    submitted: Boolean,
    /** This prop defines the behavior for when a new option is selected */
    onChangeSelection: Function,
    /** This prop indicates whether a value is required for this input or not */
    required: Boolean,
    /** This prop indicates whether or not this input is editable */
    disabled: Boolean,
    /** This prop indicates whether or not a label must be shown for this input */
    showLabel: Boolean,
    /** This prop indicates at which index of the form feild this option is situated at */
    fieldIndex: Number
  },
  data () {
    return {
      selected: null
    }
  },
  validations () {
    return {
      // eslint-disable-next-line no-constant-condition
      selected: { required: this.required ? required : false }
    }
  },
  watch: {
    value () {
      this.selected = this.value
    },
    selected () {
      this.$v.$touch()
      if (this.selected === null || this.selected === undefined) {
        this.$emit('onDeselect')
      } else {
        this.$emit('onChangeSelection', this.selected, this.$v.$invalid, this.fieldIndex)
      }
    }
  },
  mounted () {
    this.selected = this.value
    this.$v.$touch()
    if (this.selected) {
      this.$emit('onChangeSelection', this.selected, this.$v.$invalid, this.fieldIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.form-group {
p {
  display: flex;
  margin: 0px;
  color: #7f8fa4;
  font-size: 12px;
  font-family: Quicksand;
  padding: 0px 20px 0px 0px;
  display: flex;
  line-height: 20px;
  sup {
      font-family: Roboto;
      font-size: 12px;
    }
}
}
.required-error {
  color: red;
  position: absolute;
  font-size: 12px;
}
.required {
  color: red;
}

</style>
